// Importar módulos
/*import './utils/scrollbar-width.js';
import './utils/toggle-main-menu.js';*/


/**************************Scrollbar - general width*************************/
// Comentado para evitar posibles interferencias con el scroll en Firefox
const getScrollBarWidth = () => innerWidth - document.documentElement.clientWidth;
document.documentElement.style.setProperty('--scroll-bar', getScrollBarWidth());


/**************************Toggle menu button******************************/
const showMenu = (toggleId, navId) => {
  const toggle = document.getElementById(toggleId),
      nav = document.getElementById(navId)
  if (toggle && nav) {
      toggle.addEventListener('click', () => {
          nav.classList.toggle('show')
      })
  }
}

showMenu('main-menu-toggle', 'main-nav')


// Cambiar el tema manualmente
function setTheme(theme) {
  document.documentElement.style.setProperty('--theme-selector', theme);
}

// Usar la función para cambiar a tema oscuro o claro
setTheme('dark'); // o 'light'


/***************************** Header responde a las variables CSS que lo controlan */
/***************************** Header responde a las variables CSS que lo controlan */
document.addEventListener('DOMContentLoaded', () => {
  const header = document.querySelector('.main-header');
  const body = document.body;

  if (header) {
      // Obtener el valor de la variable CSS --header-sticky
      const headerStyles = getComputedStyle(header);
      const isHeaderSticky = headerStyles.getPropertyValue('--header-sticky').trim() === 'true';

      // Obtener el valor de --header-show-on-scroll
      const headerShowOnScroll = headerStyles.getPropertyValue('--header-show-on-scroll').trim() === 'true';

      let lastScrollTop = 0;
      let scrollTimeout;
      const scrollThreshold = 20; // Umbral de píxeles para considerar que hubo scroll significativo

      if (isHeaderSticky) {
          // Establecer la posición del header a 'fixed'
          header.style.position = 'fixed';
          header.style.top = '0';
          header.style.left = '0';
          header.style.right = '0';
          header.style.zIndex = '1000'; // Aseguramos que el header esté por encima del contenido

          // Definir una altura específica si no está definida en CSS
          if (!header.style.height) {
              header.style.height = '60px'; // Ajusta este valor según la altura de tu header
          }

          // Asegurar que el header no bloquee eventos de scroll
          header.style.pointerEvents = 'auto';

          // Ajustar el event listener de scroll
          window.addEventListener('scroll', onScrollHandler, { passive: true });

          function onScrollHandler() {
              const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

              // Control de transparencia en la parte superior
              if (scrollTop > 0) {
                  header.classList.add('main-header--solid');
                  body.classList.add('body--with-padding');
              } else {
                  header.classList.remove('main-header--solid');
                  body.classList.remove('body--with-padding');
              }

              // Mostrar/ocultar header al hacer scroll (si está habilitado)
              if (headerShowOnScroll) {
                  clearTimeout(scrollTimeout);

                  // Solo si el desplazamiento es mayor al umbral
                  if (Math.abs(scrollTop - lastScrollTop) > scrollThreshold) {
                      if (scrollTop > lastScrollTop) {
                          // Scroll hacia abajo
                          header.classList.add('main-header--hidden');
                      } else {
                          // Scroll hacia arriba
                          header.classList.remove('main-header--hidden');
                      }
                      lastScrollTop = scrollTop;
                  }

                  // Evitar que pequeños movimientos oculten el header
                  scrollTimeout = setTimeout(() => {
                      lastScrollTop = scrollTop;
                  }, 100);
              }
          }
      } else {
          // Si el header no es sticky, establecer posición relativa
          header.style.position = 'relative';

          // Controlar el fondo sólido al hacer scroll
          window.addEventListener('scroll', () => {
              const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

              // Control de transparencia en la parte superior
              if (scrollTop > 0) {
                  header.classList.add('main-header--solid');
              } else {
                  header.classList.remove('main-header--solid');
              }
          }, { passive: true });
      }
  } else {
      console.warn('No se encontró el elemento .main-header');
  }
});



/*****************************************************************************************************/
/*************************************************** EFECTOS *****************************************/
/*****************************************************************************************************/



// Llamamos a la función cuando sea necesario, por ejemplo, después de filtrar productos
// applyFilterAnimations();

/** 
* Animaciones para grillas de productos filtrados (fade-in-up, fade-out-down)
*/
function applyGridAnimations() {
  const fadeInUpElements = document.querySelectorAll('.fade-in-up');
  fadeInUpElements.forEach(element => {
      element.classList.add('fade-in-up-animation');
  });

  const fadeOutDownElements = document.querySelectorAll('.fade-out-down');
  fadeOutDownElements.forEach(element => {
      element.classList.add('fade-out-down-animation');
  });
}

// Llamamos a la función cuando sea necesario, por ejemplo, al mostrar u ocultar productos
// applyGridAnimations();

/** 
* Función para truncar texto según diferentes criterios
*/
function truncateText(element, options) {
  const { type, value } = options;
  const originalText = element.innerText;

  if (type === 'lines') {
      // Truncar por número de líneas
      element.style.display = '-webkit-box';
      element.style.webkitBoxOrient = 'vertical';
      element.style.overflow = 'hidden';
      element.style.textOverflow = 'ellipsis';
      element.style.webkitLineClamp = value; // Número de líneas
  } else if (type === 'words') {
      // Truncar por número de palabras
      const words = originalText.split(' ');
      if (words.length > value) {
          element.innerText = words.slice(0, value).join(' ') + '...';
      }
  } else if (type === 'chars') {
      // Truncar por número de caracteres
      if (originalText.length > value) {
          element.innerText = originalText.slice(0, value) + '...';
      }
  }
}

/** 
* Aplicación de la función truncateText según las clases asignadas
*/
document.querySelectorAll('[class*="truncate-"]').forEach(element => {
  element.classList.forEach(className => {
      if (className.startsWith('truncate-by-lines-')) {
          const lines = parseInt(className.split('-').pop());
          truncateText(element, { type: 'lines', value: lines });
      } else if (className.startsWith('truncate-by-words-')) {
          const words = parseInt(className.split('-').pop());
          truncateText(element, { type: 'words', value: words });
      } else if (className.startsWith('truncate-by-chars-')) {
          const chars = parseInt(className.split('-').pop());
          truncateText(element, { type: 'chars', value: chars });
      }
  });
});

/** 
* Código para la página de checkout
* Muestra la opción de entrega seleccionada almacenada en localStorage
*/
document.addEventListener('DOMContentLoaded', () => {
  const selectedDeliveryOption = document.getElementById('selected-delivery-option');
  const deliveryOption = localStorage.getItem('deliveryOption') || 'No seleccionado';

  // Mostrar opción de entrega
  if (selectedDeliveryOption) {
      selectedDeliveryOption.innerText = deliveryOption === 'localPickup' ? 'Recoger en local' : 'Entrega a domicilio';
  }
});

/** 
* Código para la barra lateral con pestañas (sidebar 002)
*/
document.addEventListener('DOMContentLoaded', function () {
  const tabButtons = document.querySelectorAll('.sidebar__tab-button');
  const tabPanels = document.querySelectorAll('.sidebar__tab-panel');

  tabButtons.forEach((button) => {
      button.addEventListener('click', () => {
          // Remover la clase activa de todos los botones y paneles
          tabButtons.forEach((btn) => btn.classList.remove('sidebar__tab-button--active'));
          tabPanels.forEach((panel) => panel.classList.remove('sidebar__tab-panel--active'));

          // Añadir la clase activa al botón y panel correspondiente
          button.classList.add('sidebar__tab-button--active');
          const tabId = button.getAttribute('data-tab');
          document.getElementById(tabId).classList.add('sidebar__tab-panel--active');
      });
  });
});


/*********************************************************SWIPER */

/*document.addEventListener('DOMContentLoaded', function () {
  const swiper = new Swiper('.swiper', {
      slidesPerView: 1.2, // Muestra un slide entero y una parte mayor del siguiente
      spaceBetween: 20,
      centeredSlides: true,
      loop: true,
      pagination: {
          el: '.product-slider__pagination',
          clickable: true,
      },
      on: {
          slideChange: () => updateProductInfo(swiper.activeIndex),
      }
  });
});*/







  







function updateBackground() {
    const title = document.getElementById('product-title').innerHTML.trim();
    const decodedTitle = decodeURIComponent(title);
    const numLines = 5;  // Número de líneas de texto
    const svgWidth = 5000;  // Ancho suficiente para mejor repetición
    const svgHeight = 700;  // Altura del SVG
    const lineHeight = svgHeight / numLines;  // Altura para cada línea de texto
    const fontSize = lineHeight * 1.1;  // Tamaño de la fuente

    let svgContent = '';
    for (let i = 0; i < numLines; i++) {
        const yPos = lineHeight * i + (lineHeight / 2);  // Posición vertical de cada línea
        const direction = i % 2 === 0 ? 1 : -1;  // Alternar dirección
        // Ajustar la posición inicial y final para que el texto esté siempre visible
        const fromX = direction === 1 ? -svgWidth : 0;
        const toX = direction === 1 ? 0 : -svgWidth;
        svgContent += `<text x='${fromX}' y='${yPos}' class='svg-text' style="fill:${i % 2 === 0 ? '#fff' : '#000'}; font-family: 'Bebas Neue', sans-serif; transform: rotate(${i % 2 === 0 ? '0deg' : '0deg'});">
            ${new Array(40).fill(decodedTitle).join(' ')}  <!-- Incrementar la longitud del texto para asegurar cobertura -->
            <animate
                attributeName="x"
                from="${fromX}"
                to="${toX}"
                dur="60s"
                repeatCount="indefinite"/>
        </text>`;
    }

    const svg = `<svg xmlns='http://www.w3.org/2000/svg' width='${svgWidth}' height='${svgHeight}' viewBox='0 0 ${svgWidth} ${svgHeight}'>
    <defs>
        <style>
            .svg-text { 
                font-size: ${fontSize}px;
                font-weight: 900;  // Más grueso que el bold normal
                mix-blend-mode: screen;
                font-family: 'Bebas Neue', Arial, sans-serif;  // Asegúrate de que la fuente soporta el peso
            }
        </style>
    </defs>
    ${svgContent}
    </svg>`;

    const encodedSvg = btoa(unescape(encodeURIComponent(svg)));
    const section = document.getElementById('section_03');
    section.style.setProperty('--background-svg', `url('data:image/svg+xml;base64,${encodedSvg}')`);
}





document.addEventListener("DOMContentLoaded", function () {
    const counters = document.querySelectorAll('.count');
    const speed = 200; // Velocidad del contador
  
    counters.forEach((counter) => {
      const updateCount = () => {
        const target = parseInt(counter.getAttribute('data-target'));
        const count = parseInt(counter.innerText);
        const increment = Math.trunc(target / speed);
  
        if (count < target) {
          counter.innerText = count + increment;
          setTimeout(updateCount, 10); // Ajusta la velocidad del incremento
        } else {
          counter.innerText = target;
        }
      };
  
      updateCount();
    });

  });

/**************************Habilita el desplazamiento en x con la rueda del raton*************************/
/*document.addEventListener("DOMContentLoaded", function() {
  const container = document.querySelector(".slide-container-x");

  container.addEventListener("wheel", function(e) {
      // Verifica si no se está presionando Shift y que hay desplazamiento en el eje Y (deltaY)
      if (!e.shiftKey && e.deltaY !== 0) {
          e.preventDefault();  // Evita el desplazamiento vertical
          container.scrollLeft += e.deltaY; // Usa deltaY para desplazarse horizontalmente
      }
  }, { passive: false });  // Marcado como no pasivo para poder usar preventDefault
});*/


document.addEventListener('DOMContentLoaded', function() {
    const marca = document.getElementById('marca');
    const brandPopup = document.getElementById('brandPopup');
    const mainContent = document.getElementById('mainContent');
    const closeButton = document.getElementById('closeButton');
    const closeButtonSecondary = document.getElementById('closeButtonSecondary');

    // Función para abrir el popup
    function openPopup() {
        brandPopup.style.display = 'flex';
        brandPopup.classList.add('show');
        mainContent.style.filter = 'blur(5px)';
        mainContent.style.pointerEvents = 'none';
    }

    // Función para cerrar el popup
    function closePopup() {
        brandPopup.style.display = 'none';
        brandPopup.classList.remove('show');
        mainContent.style.filter = 'none';
        mainContent.style.pointerEvents = 'auto';
      }

    // Abrir el popup al hacer clic en "Marca"
    marca.addEventListener('click', openPopup);

    // Cerrar el popup al hacer clic en el botón de cerrar (X)
    closeButton.addEventListener('click', closePopup);

    // Cerrar el popup al hacer clic en el botón de cerrar secundario
    closeButtonSecondary.addEventListener('click', closePopup);

    // Cerrar el popup al hacer clic fuera del contenido del popup
    brandPopup.addEventListener('click', function(e) {
        if (e.target === brandPopup) {
            closePopup();
        }
    });
}); 


